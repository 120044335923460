import React, { useState, useReducer, createContext } from "react";

import useAsyncReducer from "./useAsyncReducer";

import { unsubscribeReducer } from "./reducers";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  // const [masterSetup, setMasterSetup] = useState([]);
  // const [masterContent, setMasterContent] = useState([]);

  /* unsubscribeReducer reducer */
  const [unsubscribeState, unsubscribeDispatch] = useAsyncReducer(
    unsubscribeReducer.reducer,
    {
      ...unsubscribeReducer.init
    }
  );

  const store = {
    unsubscribe: {
      state: unsubscribeState,
      dispatch: unsubscribeDispatch,
      actionTypes: unsubscribeReducer.actionTypes
    }
  };

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  );
};
