import React, { lazy, Suspense } from "react";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import { Inputs, Buttons } from "../../lib-atomic";
import { LibContainer, LibText } from "../../lib-atomic-design-section";
/* packages */

/* layout */
import UnsubscribeLayout from "../../app-module-unsubscribe-layout/default";
/* layout */

// /* lazy modules */
// const CMSLayout = lazy(() =>
//   import("../../app-module-cms-section-layout/default")
// );
// /* lazy modules */

const index = (props) => {
  return (
    <GlobalProvider>
      <UnsubscribeLayout {...props}>
        <br />
        <br />
        <LibText variant="body1">
          You have successfully unsubscribed to Paper Plus
        </LibText>
        <br />
        <br />
        <LibText variant="body1">Thank you,</LibText>
        <LibText variant="body1">
          <b>Paper Plus NZ</b>
        </LibText>
      </UnsubscribeLayout>
    </GlobalProvider>
  );
};

export default index;
