// import Login from "../../app-module-admin-login";
// import LoginRedirect from "../../app-module-admin-login-redirect";
// import Logout from "../../app-module-admin-logout";
// import TokenExpire from "../../app-module-admin-token-expire";
// import ResetPassword from "../../app-module-admin-reset-password";
// import Registration from "../../app-module-admin-register";
// import IdleUser from "../../app-module-admin-landing-idle-user";

import Unsubscribe from "../../app-module-unsubscribe-page-landing";

const AdminModules = [
  // Login,
  // Logout,
  // TokenExpire,
  // LoginRedirect,
  // ResetPassword,
  // Registration,
  // IdleUser,
  Unsubscribe
];

export default AdminModules;
