import React, { useContext } from 'react'
import { Route, Switch } from "react-router-dom";

import CONFIG from './config';

import Default from './default'

const Routes = props => {
  const { label, prefix } = CONFIG.routes;
  return <Switch>
    <Route exact path={prefix} component={Default} />
  </Switch>
}

export default Routes;
