import { init } from "./init";
import { sanitizeData, sanitizeExpire } from "./Sanitize";

const actionTypes = {
  FETCH_UNSUBSCRIBE: "FETCH_UNSUBSCRIBE"
};

const fetchUnsubscribe = async (state, unsubscribe) => {
  state = {
    ...state,
    isStatus: unsubscribe.status,
    expireToken: sanitizeExpire({
      status: unsubscribe.status,
      data: unsubscribe.data,
      name: "unsubscribeObject"
    }),
    data: sanitizeData({
      status: unsubscribe.status,
      data: unsubscribe.data,
      name: "unsubscribeObject"
    })
  };
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UNSUBSCRIBE:
      return fetchUnsubscribe(state, action.unsubscribe);
    default:
      return state;
  }
};

const unsubscribe = {
  init,
  actionTypes,
  reducer
};

export default unsubscribe;
