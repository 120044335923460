import React, { useEffect, useContext } from "react";
import QueryString from "query-string";

/* packages */
import { GlobalContext } from "../../lib-atomic-context";
import { FetchContextApi, FetchContextApiV2 } from "../../lib-atomic-functions";
/* packages */

/* module */
import Spinner from "../../app-module-unsubscribe-spinner";
/* module */

import CONFIG from "../config";

/* CONSTANTS */
const UNSUBSCRIBEENDPOINTS = CONFIG.application.cms.api.endpoints;
const UNSUBSCRIBECONFIG = {
  url: UNSUBSCRIBEENDPOINTS.unsubscribe.url,
  method: UNSUBSCRIBEENDPOINTS.unsubscribe.method,
  headers: UNSUBSCRIBEENDPOINTS.unsubscribe.headers,
  responseType: "json",
};
/* CONSTANTS */

const index = (props) => {
  const { modulecode, token } = props.match.params;
  //console.log(modulecode, token, " TEST..");

  // /* state */
  const { unsubscribe } = useContext(GlobalContext);

  /* --- unsubscribe --- */
  UNSUBSCRIBECONFIG.headers.moduleCode = modulecode;
  const [fetchUnsubscribe] = FetchContextApiV2(UNSUBSCRIBECONFIG);
  const fetchUnsubscribeExecute = async ({ body }) => {
    unsubscribe.dispatch({
      type: unsubscribe.actionTypes.FETCH_UNSUBSCRIBE,
      setup: await fetchUnsubscribe({ body }, unsubscribe),
    });
  };
  /* --- unsubscribe --- */

  useEffect(() => {
    const body = {
      token,
      flEmail: 0,
      flSms: null,
    };
    fetchUnsubscribeExecute({ body });
  }, []);

  const { usubscribeObject } = unsubscribe.state.data;

  const checkResult = () => {
    return !usubscribeObject ? false : true;
  };

  if (!checkResult) return <Spinner />;

  return props.children;
};

export default index;
