import React from 'react'
import { Link } from 'react-router-dom';

import CONFIG from './config';

const App = props => {
  const url = props.match ? props.match.url : "";
  const { label, prefix } = CONFIG.routes;
  return null;
}

export default App;
