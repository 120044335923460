import React, { lazy, Suspense } from "react";

/* packages */
import { GlobalProvider } from "../../lib-atomic-context";
import { Inputs, Buttons } from "../../lib-atomic";
import { FormHook } from "../../lib-atomic-functions";
import { LibContainer, LibText } from "../../lib-atomic-design-section";
/* packages */

/* container */
import UnsubscribeContainer from "../../app-module-unsubscribe-container/default";
/* container */

/* modules */
import Spinner from "../../app-module-unsubscribe-spinner/default";
/* modules */

// /* lazy modules */
// const CMSLayout = lazy(() =>
//   import("../../app-module-cms-section-layout/default")
// );
// /* lazy modules */

import CONFIG from "../config";

const index = (props) => {
  const { logo } = CONFIG.setup.assets;

  return (
    <GlobalProvider>
      <UnsubscribeContainer {...props}>
        {/* header */}

        <LibContainer size="sm">
          <div align="center">
            <img src={logo} />
          </div>
        </LibContainer>
        {/* header */}
        {/* header */}
        <LibContainer size="sm">
          <LibText variant="h4">Unsubscribed</LibText>
        </LibContainer>
        {/* header */}
        {/* content */}
        <LibContainer size="sm">{props.children}</LibContainer>
        {/* content */}
        {/* footer */}
        {/* <LibContainer size="sm">footer</LibContainer> */}
        {/* footer */}
      </UnsubscribeContainer>
    </GlobalProvider>
  );
};

export default index;
