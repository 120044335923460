const environment = process.env.NODE_ENV;
const project = "dashboard";
const server = null;
const service = null;
const config = {
  details: {
    project,
    server,
    environment,
    service,
  },
  routes: {
    label: "Dashboard",
    prefix: "/coporate"
  }
}

export default config;
