import appConf from "../app-conf";
const setup = appConf[process.env.REACT_APP_CLIENT];
const environment = process.env.NODE_ENV;
const project = "Unsubscribe";
const server = null;
const service = null;

const config = {
  details: {
    project,
    server,
    environment,
    service
  },
  application: {
    preview: {
      api: {
        endpoints: false
      },
      objects: {
        setupname: {
          name: "setupname",
          initial: "",
          label: "Setup Name",
          helperText: false,
          rules: ["isNotNull"]
        },
        platform: {
          name: "platform",
          initial: "",
          label: "Platform",
          helperText: false,
          rules: ["isNotNull"]
        }
      }
    }
  },
  routes: {
    label: "Unsubscribe",
    prefix: "/unsubscribe"
  },
  setup
};

export default config;
