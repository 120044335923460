import React from "react";

/* mui */
import Typography from "@material-ui/core/Typography";
/* mui */

const index = (props) => {
  // variant: body1, body2, etc ...
  const { variant, children } = props;
  return <Typography variant={variant}>{children}</Typography>;
};

export default index;
