import React from "react";

/* mui */
import Container from "@material-ui/core/Container";
/* mui */

const index = (props) => {
  // size: lg, sm, md
  const { size, children } = props;
  return <Container maxWidth={size}>{children}</Container>;
};

export default index;
