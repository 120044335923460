import { useState, useEffect } from "react";

/* packages */
import { WindowSize } from "../../lib-atomic-functions";
/* packages */

const layoutSize = (width, height, platform) => {
  const heightRatio = platform === "mob" ? 0.5 : 1.2;
  const sizesWeb = [
    { minWidth: 900, maxWidth: 100000, layoutCols: 4 },
    { minWidth: 800, maxWidth: 900, layoutCols: 4 },
    { minWidth: 700, maxWidth: 800, layoutCols: 4 },
    { minWidth: 600, maxWidth: 700, layoutCols: 4 },
    { minWidth: 500, maxWidth: 600, layoutCols: 4 },
    { minWidth: 400, maxWidth: 500, layoutCols: 2 },
    { minWidth: 300, maxWidth: 400, layoutCols: 2 },
    { minWidth: 1, maxWidth: 300, layoutCols: 1 }
  ];
  const sizesMob = [
    { minWidth: 900, maxWidth: 100000, layoutCols: 2 },
    { minWidth: 800, maxWidth: 900, layoutCols: 2 },
    { minWidth: 700, maxWidth: 800, layoutCols: 2 },
    { minWidth: 600, maxWidth: 700, layoutCols: 2 },
    { minWidth: 500, maxWidth: 600, layoutCols: 2 },
    { minWidth: 400, maxWidth: 500, layoutCols: 2 },
    { minWidth: 300, maxWidth: 400, layoutCols: 2 },
    { minWidth: 1, maxWidth: 300, layoutCols: 2 }
  ];
  const sizes = platform === "mob" ? sizesMob : sizesWeb;

  const bracket = sizes.filter(
    size => width >= size.minWidth && width <= size.maxWidth
  )[0];
  const getFullWidth = platform => {
    console.log(platform);
    return platform === "mob" ? (width * 35) / 100 : (width * 50) / 100;
  };
  const getLayoutWidth = bracket => {
    let bracketWidth = bracket.minWidth;
    if (bracketWidth < 400) bracketWidth = bracket.maxWidth;
    return bracketWidth;
  };

  const getLayoutHeight = bracket => {
    return (heightRatio / bracket.layoutCols) * getLayoutWidth(bracket);
  };

  const getColsWidth = boxWidth => {
    return parseInt(boxWidth) <= bracket.layoutCols
      ? parseInt(boxWidth)
      : bracket.layoutCols;
  };

  const getColsHeight = boxHeight => {
    if (bracket.layoutCols < 2) return getLayoutHeight(bracket);
    return parseInt(boxHeight) * getLayoutHeight(bracket);
  };

  const getColsWidthHeight = ({ width, height }) => {
    console.log("a: ", getColsHeight(height));
    return ([width, height] = [getColsWidth(width), getColsHeight(height)]);
  };

  const layout = {
    layoutWidth: getLayoutWidth(bracket),
    layoutHeight: getLayoutHeight(bracket),
    layoutCols: bracket.layoutCols,
    width,
    height,
    getColsWidth,
    getColsHeight,
    getColsWidthHeight,
    getFullWidth
  };

  return layout;
};

export default layoutSize;
