import React from "react";
import { Route, Switch } from "react-router-dom";

import Offer from "./layout/main";

const App = props => {
  return (
    <Switch>
      <Route path="/u" component={Offer} />
    </Switch>
  );
};

export default App;
