import React from "react";

/* mui */
import Container from "@material-ui/core/Container";
/* mui */

import Imports from "./imports";

import appConf from "../../components/app-conf";
const Themes = appConf[process.env.REACT_APP_CLIENT].themes;

const landingXs = props => {
  return (
    <Themes>
      <Container maxWidth="xl">
        <Imports {...props} />
      </Container>
    </Themes>
  );
};

export default landingXs;
