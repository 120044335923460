export default {
  project: "Thq-Westfield-Corporate",
  name: "Thq-Westfield-Corporate",
  url: "http://thq-westfield.s3-website-ap-southeast-2.amazonaws.com",
  auth_landing_module: "/ordering",
  idle_limit: 1800000, //180000,
  thor: {
    moduleCode: {
      production: "SUB_STORMBORN",
      development: "SUB_STORMBORN",
    },
    programId: null,
  },
  api: {
    tranxactor: {
      url: {
        production: "https://cm-contactability.tranxactor.com",
        development: "https://tranxactor-uat.txweb.site",
      },
    },
    cms: {
      url: {
        // production: "https://stormborn-strapi.tr4ns.com",
        // development: "https://stormborn-strapi.tr4ns.com"
      },
    },
    stormborn: {
      url: {
        // production: "https://subwayuat.tr4ns.com/tx-auth",
        // development: "https://subwayuat.tr4ns.com/tx-auth"
      },
    },
  },
};
