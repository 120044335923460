import React from "react";

import { Row } from 'reactstrap';

import Imports from './imports';

const Index = (props) => {
  return <div>hello!</div>
}

export default Index;
