import React from 'react'
import { Link } from 'react-router-dom';

import CONFIG from './config';

const Atom =  props => {
  const { label, prefix } = CONFIG.routes;
  return null;
  return <Link to={prefix}>List</Link>
}

export default Atom;
